import { setCookie }  from 'nookies';
import settings       from '@frontend/settings/components/popup';
import _get           from 'lodash/get';

export const setPopupBlock = (block, key) =>{
  return {
    type: "SET_POPUP_BLOCK",
    block,
    key
  }
}

export const setPopupVisibility = (visibility, key) =>{
  if(!visibility){
    setCookie({}, "main-popup", visibility, {
      maxAge: _get(settings, `byKey.${_get(settings, "keys.main")}.cookieMaxAge`),
      path: '/',
    });
  }

  return {
    type: "SET_POPUP_VISIBILITY",
    visibility,
    key
  }
}