export const popup = {
  keys:{
    main: "main"
  },
  byKey: {
    main: {
      cookieMaxAge: 60 * 60
    }
  }
}

export default popup;