import moment         from 'moment';
import dayjs          from 'dayjs';
import utc            from 'dayjs/plugin/utc'
import timezone       from 'dayjs/plugin/timezone'
import _find          from 'lodash/find';


export function getProductPrices(
  { user, excludedTaxCustomerGroup, defaultCountry, prices, storeConfig = { productPriceIncludeTax: "0" } }
) {
  let regular_price = parseFloat(prices.regular_price);
  let final_price = parseFloat(prices.final_price);
  let tier_price = null;

  // let actualDate = new Date().toLocaleString('es-ES', { timeZone: 'Europe/Madrid' })  
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  dayjs.tz.setDefault("Europe/Madrid")
  let shortDate = dayjs();
  // console.log("actualDate",actualDate,"shortDate",shortDate)
  // console.log("date moment",moment());
  // let actualDate = moment();
  // actualDate.tz("Europe/Madrid");
  let fromDate = null;
  let toDate = null;
  let isInDate = true;
  let customerGroup = (user && user.group_id) ? user.group_id : 0;
  if (prices.special_price) {
    if (prices.special_price_from) {
      fromDate = dayjs(prices.special_price_from);
      if (fromDate > shortDate) {
        isInDate = false;
      }
    }
    if (prices.special_price_to) {
      toDate = dayjs(prices.special_price_to)
      if (toDate < shortDate) {
        isInDate = false;
      }
    }
    if (prices.special_price < final_price && isInDate) {
      final_price = parseFloat(prices.special_price);
    }
  }
  if (prices.catalog_rule_price) {
    isInDate = true;
    let catalogRulePrices = JSON.parse(prices.catalog_rule_price);
    catalogRulePrices = catalogRulePrices.filter(item => item.customer_group == customerGroup);
    if (catalogRulePrices.length) {
      let rule = catalogRulePrices[0];
      if (rule.from) {
        fromDate = rule.from;
        fromDate = dayjs(fromDate)
        if (fromDate > shortDate) {
          isInDate = false;
        }
      }
      if (rule.to) {
        toDate = rule.to;
        toDate = dayjs(toDate)
        // toDate = toDate.hour(23);
        // toDate = toDate.minute(59);
        if (toDate < shortDate) {
          isInDate = false;
        }
      }
      let rule_price = parseFloat(rule.rule_price)
      if (rule_price > 0 && rule_price < regular_price && isInDate) {
        final_price = parseFloat(rule.rule_price);
      } else {
        final_price = regular_price;
      }
    }
  }
  if (prices.tier_prices && prices.tier_prices.length > 2) {
    let rawTier = false;
    try {
      rawTier = JSON.parse(prices.tier_prices);
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
    if (rawTier != false) {
      let tierPrices = [];
      rawTier.map(item => {
        tierPrices.push(JSON.parse(item));
      });
      if (tierPrices) {
        tierPrices = tierPrices.filter(item => item.customerGroupId == customerGroup);
        if (tierPrices.length) {
          tier_price = parseFloat(tierPrices[0].price)
        }
      }
    }
  }
  // tier prices
  user = user || {}
  if (storeConfig.productPriceIncludeTax == "0" && prices.tax_rate && (!(user.id) || (user && user.id && !excludedTaxCustomerGroup.includes(customerGroup.toString())))) {
    let taxRates = JSON.parse(prices.tax_rate);
    if (taxRates) {
      taxRates = taxRates.filter(item => item.country_id == defaultCountry && item.customer_group == customerGroup);
      if (taxRates.length) {
        final_price = final_price + (final_price * parseFloat(taxRates[0].rate)) / 100;
        regular_price = regular_price + (regular_price * parseFloat(taxRates[0].rate)) / 100;
        tier_price = tier_price + (tier_price * parseFloat(taxRates[0].rate)) / 100;
      }
    }
  }
  return {
    regular_price,
    final_price,
    tier_price,
    fromDate,
    toDate
  }


}